import { menuNotification } from './interfacesMenuNotificaciones';
import styles from '../../../styles/components/pst/header-pst.module.scss';
import ItemMenuNotification from './item';
import { useTranslations } from 'next-intl';

const MenuNotificaciones = (itemMenu: menuNotification) => {
  console.log('itemMenu', itemMenu);
  const t = useTranslations('Header');
  return (
    <>
      <div className={styles.menu}>
        <ul>
          <li
            style={{
              backgroundColor: '#F7F7F7',
              minWidth: '438px',
              paddingBottom: '5px',
              maxHeight: '34px',
            }}
          >
            <img src="/images/svg/info.svg" alt="" />
            <span
              style={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              {t('notificaciones')}
            </span>
          </li>
          {itemMenu?.itemMenu?.length === 0 && (
            <li
              style={{
                height: '70px',
                margin: '0 8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#24252e',
                }}
              >
                No tienes notificaciones
              </p>
            </li>
          )}
          {itemMenu?.itemMenu?.map((item, index) => {
            return (
              <ItemMenuNotification
                key={index}
                clientName={item.clientName}
                reservationDate={item.reservationDate}
                serviceName={item.serviceName}
                notificationType={item.notificationType}
                idOferta={item.idOferta}
                wellRead={item.wellRead}
                id={item.id}
                idReservation={item.idReservation}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default MenuNotificaciones;
